export function useSearchParams<T extends ZodTypeAny>(searchParamsSchema: T) {
  const searchParams = Object.fromEntries(useRequestURL().searchParams);
  const validatedSearchParams = searchParamsSchema.safeParse(searchParams);

  if (!validatedSearchParams.success) {
    throw new Error("Invalid search params");
  }

  return validatedSearchParams.data as output<T>;
}
